import styles from './style.module.scss';
import { SOLUTIONS } from '/utils/const/home';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';
import { useState, useRef, useEffect } from 'react';
import { src, handleRoute } from '/utils/common';
import FadeIn from '/components/commonComponents/fadeIn';
import StreamLakeVideo from '../../StreamLakeVideo';
import { useUserAgentInfo } from '../../../context/user-agent';

const SoluSlider = () => {
  const { isMobile, browserName } = useUserAgentInfo();
  const sliderRef = useRef(null);
  const { title, description, features } = SOLUTIONS;

  const shouldShowDirection =
    !isMobile || browserName === 'QQBrowser' || browserName === 'UCBrowser';

  useEffect(() => {
    const currIcon = document.querySelector(
      `.slick-slide[data-index="${0}"] video`
    );
    currIcon?.play();
  });

  const handleSliderChange = (curr, next) => {
    const nextIcon = document.querySelector(
      `.slick-slide[data-index="${next}"] video`
    );
    if (nextIcon) {
      nextIcon?.play();
    }
    const currIcon = document.querySelector(
      `.slick-slide[data-index="${curr}"] video`
    );
    if (currIcon) {
      currIcon.pause();
      currIcon.currentTime = 0;
    }
  };
  const toggleScroll = throttle((direction) => {
    if (direction === 'left') {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  }, 500);
  return (
    <div className={styles.solutions}>
      <div className={styles.title}>{title}</div>
      <div className={styles.descText}>{description}</div>
      <FadeIn>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <Slider
              dots={false}
              infinite={true}
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              arrows={false}
              beforeChange={(curr, next) => handleSliderChange(curr, next)}
              centerMode={true}
              centerPadding={16}
              className={styles['slick-center']}
              ref={sliderRef}
              responsive={[
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '54px',
                    speed: 500,
                  },
                },
              ]}
            >
              {features.map((item, index) => (
                <div key={index}>
                  <div
                    className={styles.brandCard}
                    onClick={() => handleRoute(item.link)}
                  >
                    {isMobile ? (
                      <img
                        src={src(item.gif)}
                        className={styles['video-icon']}
                      ></img>
                    ) : (
                      <StreamLakeVideo
                        loop
                        muted
                        src={src(item.icon)}
                        id="solution-icon"
                        className={styles['video-icon']}
                        webkit-playsinline="true"
                        playsInline={true}
                        x5-playsinline="true"
                        poster={src(item.poster)}
                      />
                    )}

                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.content}>{item.content}</div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div
            className={`${styles.btn} ${styles.left}`}
            style={shouldShowDirection ? { display: 'block' } : {}}
            onClick={() => toggleScroll('left')}
          />
          <div
            className={`${styles.btn} ${styles.right}`}
            style={shouldShowDirection ? { display: 'block' } : {}}
            onClick={() => toggleScroll('right')}
          />
        </div>
      </FadeIn>
    </div>
  );
};
export default SoluSlider;
