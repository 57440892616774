import { Button } from 'antd';
import FadeIn from '/components/commonComponents/fadeIn';
import { WebPNextImage as Image } from '../../WebPImage';
import styles from './style.module.scss';
import { BANNER_DATA, FEATURES } from '/utils/const/values';
import ExpGroup from '/components/commonComponents/expGroup';
import ContactTip from '/components/commonComponents/contactTip';
import { handleRoute } from '/utils/common';

const expValue = () => {
  return (
    <>
      <div className={`${styles.experienceWrapper}`}>
        <div className={`${styles.background}`}></div>
        <div className={`${styles.experience}`} id="experience">
          <FadeIn>
            <div className={styles.wrapper}>
              <div className={styles.title}>{BANNER_DATA.title}</div>
              <div className={styles.descText}>{BANNER_DATA.description}</div>
              <Button
                className={styles.button}
                onClick={() => handleRoute('/form/consult/try')}
              >
                开始试用
              </Button>
              <span style={{ marginLeft: '19px' }}>
                <ContactTip />
              </span>
            </div>
          </FadeIn>
        </div>
      </div>
      <div className={styles.expGroup}>
        <div className={styles.wrapper}>
          <FadeIn cascade>
            <div className={styles.expContainer}>
              {FEATURES.map((item, index) => (
                <div key={index} className={styles.expItem}>
                  <Image src={item.picUrl} alt="" width={88} height={88} />
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.content}>{item.content}</div>
                </div>
              ))}
            </div>
          </FadeIn>
        </div>
      </div>
      {/* 手机 */}
      <div className={styles['value-wrapper']}>
        <header className={styles.title}>{BANNER_DATA.title}</header>
        <section className={styles.dec}>{BANNER_DATA.description}</section>
        <Button
          className={styles['primary-button']}
          onClick={() => handleRoute('/form/consult/try')}
        >
          开始试用
        </Button>
        <ExpGroup features={FEATURES} />
      </div>
    </>
  );
};

export default expValue;
