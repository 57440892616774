import React, { useEffect, useRef } from 'react';
import ScopedStyle from './style.module.scss';

const Video = React.forwardRef(
  (
    props: React.VideoHTMLAttributes<HTMLVideoElement> & {
      playInNewWindow?: boolean;
      placeholderImg?: string;
      placeholderSize?: string;
    },
    outsideRef?: React.ForwardedRef<HTMLVideoElement>
  ) => {
    const {
      playInNewWindow = false,
      placeholderImg = '',
      placeholderSize = 'contain',
    } = props;
    const innerRef = useRef<HTMLVideoElement>();
    const updateRef = (vRef: HTMLVideoElement) => {
      innerRef.current = vRef;

      if (!outsideRef) {
        return;
      }
      // NOTE: outsideRef 既可能是 ref object, 也可能是一个 function
      if (typeof outsideRef === 'function') {
        outsideRef(vRef);
      } else {
        outsideRef.current = vRef;
      }
    };

    useEffect(() => {
      const forcePlay = () => {
        innerRef.current?.paused && props.autoPlay && innerRef.current.play();
      };
      window.addEventListener('touchstart', forcePlay);
      return () => window.removeEventListener('touchstart', forcePlay);
    }, []);
    if (playInNewWindow) {
      return (
        <div
          className={`${props.className || ''} ${ScopedStyle['placeholder']}`}
          style={{
            ...(props.style || {}),
            backgroundImage: `url('${placeholderImg}')`,
            backgroundSize: placeholderSize,
          }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(props.src, '_self');
          }}
        ></div>
      );
    }
    return <video {...props} ref={updateRef}></video>;
  }
);

Video.displayName = 'StreamLakeVideo';

export default Video;
