/* eslint-disable @next/next/no-img-element */
import styles from '../styles/Home.module.scss';
import { useState } from 'react';
import FadeIn from '../components/commonComponents/fadeIn';
import HomeBanner from '../components/commonComponents/homeBanner';
import TopProd from '../components/commonComponents/topProd';
import FullScreenVideo from '../components/commonComponents/fullScreenVideo';
import ExpValue from '../components/commonComponents/expValue';
import BrandCard from '../components/commonComponents/brandCard';
import SoluSlider from '../components/commonComponents/soluSlider';
import ContactCard from '../components/commonComponents/contactCard';
import { BRAND } from '../utils/const/home';
import { staticFileMap } from '../utils/static-file';
import { useUserAgentInfo } from '../context/user-agent';

function Home() {
  const [show, setShow] = useState(false);
  const { isMobile, browserName } = useUserAgentInfo();

  const shouldFullScreenOpenInWindow =
    isMobile && (browserName === 'QQBrowser' || browserName === 'UCBrowser');

  const handleFullScreen = shouldFullScreenOpenInWindow
    ? () => {
        window.open(staticFileMap['xuanchuan'], '_self');
      }
    : (status: boolean) => {
        setShow(status);
      };

  return (
    <div className={styles.container} id="home-container" key="home-container">
      {/* 全屏播放视频 */}
      <FullScreenVideo
        src={staticFileMap['xuanchuan']}
        show={show}
        clickOverlayClose={true}
        handleShow={handleFullScreen}
      />
      {/* 首部banner */}
      <HomeBanner handleClick={handleFullScreen} />
      {/* 特色产品 */}
      <TopProd handleShow={handleFullScreen} />
      {/* 客户价值 */}
      <ExpValue />
      {/* 丰富场景 */}
      <div className={styles.brandWrapper}>
        <BrandCard title={BRAND.title} config={BRAND.items} />
      </div>
      {/* 轮播图 */}
      <SoluSlider />
      {/* 生态合作 */}
      <div className={styles.contactWrapper}>
        <ContactCard />
      </div>
    </div>
  );
}

export default Home;
