import FadeIn from '../fadeIn';
import { WebPNativeImage } from '../../WebPImage';
import styles from './style.module.scss';
import { src } from '/utils/common';

const ExpGroup = ({ features }) => {
  return (
    <FadeIn cascade>
      <div className={styles['item-container']}>
        {features.map((item, index) => (
          <div key={index} className={styles['item-wrapper']}>
            <div key={index} className={styles['item-header']}>
              <WebPNativeImage
                src={src(`/img/index/exp-icon${index + 1}.webp`)}
                alt=""
                className={styles['item-img']}
              />
              <div className={styles.title}>{item.title}</div>
            </div>
            <div className={styles.content}>{item.content}</div>
          </div>
        ))}
      </div>
    </FadeIn>
  );
};

export default ExpGroup;
