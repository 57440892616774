const path = '/img/values';

export const BANNER_DATA = {
  title: '客户价值',
  backgroundImg: `${path}/banner.webp`,
  backgroundImgMobile: `${path}/bannerMobile.webp`,
  description:
    '帮助客户快速构建音视频及AI能力，对标行业最佳用户体验，助力业务快速发展，为全行业提供最具性价比的解决方案',
  buttonText: '立即咨询',
  buttonLink: '',
};
export const FEATURES = [
  {
    title: '快速构建视频业务',
    content:
      '丰富开发者工具，一定程度上开放源码、UI等，持续降低接入门槛，让客户专注于自身业务',
    picUrl: `${path}/icon1.webp`,
  },
  {
    title: '视听体验全面升级',
    content:
      '自适应智能媒体处理算法，实现视觉和听觉的全方位增强；配合播放器消费体验优化策略，体验顺滑、流畅',
    picUrl: `${path}/icon2.webp`,
  },
  {
    title: '运营成本持续优化',
    content:
      '通过编解码、融合CDN/PCDN、热度触发等核心竞争力产品及策略，帮助客户最大程度降低运营成本',
    picUrl: `${path}/icon3.webp`,
  },
];

export const REASONS = [
  {
    title: '更懂用户体验',
    content:
      'StreamLake 从各行业客户痛点出发，帮助客户打造最优的终端用户体验。提供点/直播一体化播放器、融合一系列体验优化策略，极致优化QoS（Quality of Service）及QoE（Quality of Experience）；融合市场最优CDN，优中择优，为客户的用户体验提供保障；针对场景化诉求，如中长视频场景，提供质臻影音极致视频处理算法',
    picUrl: `${path}/reason1.webp`,
    pcPicUrl: `${path}/reasonRect1.webp`,
  },
  {
    title: '更具性价比',
    content:
      '创新地提出数据驱动的一体化点/直播方案，依托自研编解码的绝对领先优势，相同画质下提升30%-60%压缩率，与数据驱动策略-热度触发融合，最大程度帮助客户节省TCO（ Total Cost of Ownership）',
    picUrl: `${path}/reason2.webp`,
    pcPicUrl: `${path}/reasonRect2.webp`,
  },
  {
    title: '更智能',
    content:
      '自身业务高速迭代过程中，使StreamLake更前瞻更丰富地进行了玩法和用户体验诉求的积累，进而保障了AI技术和场景的快速迭代，为客户提供端侧和服务端的多样性AI处理能力，帮助客户实现新场景新玩法的快速落地',
    picUrl: `${path}/reason3.webp`,
    pcPicUrl: `${path}/reasonRect3.webp`,
  },
  {
    title: '服务标准化',
    content:
      '以降低接入门槛、减少对接成本为工程化宗旨，实现标准化服务的同时，提供易用性高的接入工具，有效降低服务迁移和接入成本。同时，开放地提供专业的技术咨询和客户成功服务，加速客户的业务成长',
    picUrl: `${path}/reason4.webp`,
    pcPicUrl: `${path}/reasonRect4.webp`,
  },
];
