import { WebPNextImage as Image } from '../../WebPImage';
import styles from './style.module.scss';

const ContactTip = () => {
  return (
    <span className={styles.tips}>
      <Image
        src={'/img/index/contact.svg'}
        alt="contact"
        className={styles.icon}
        width={15}
        height={15}
      />
      <span className={styles.text}>联系我们获取专属服务</span>
    </span>
  );
};

export default ContactTip;
