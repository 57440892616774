import styles from './style.module.scss';

import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import FeatureProd from '/components/commonComponents/featureProd';
import FadeIn from '/components/commonComponents/fadeIn';
import { WebPNextImage as Image } from '../../WebPImage';
import { useWebPResourceCompatibleFn } from '../../../hooks/useWebPResource';
import { useState } from 'react';
import { src, handleRoute } from '/utils/common';
import { PRODUCTS } from '/utils/const/home';
import StreamLakeVideo from '../../StreamLakeVideo';

const TopProd = ({ handleShow }) => {
  const [selected, setSelected] = useState(0);
  const [currIndex, setCurrIndex] = useState(-1);
  const webPResourceHandleFn = useWebPResourceCompatibleFn();
  return (
    <FadeIn>
      <div className={styles.topProducts}>
        <div className={styles.tabs}>
          <div className={styles.video} onClick={() => handleShow(true)}>
            <Image src={`/img/index/play.webp`} alt="" width={45} height={45} />
          </div>
          {PRODUCTS.map((item, index) => (
            <div
              key={index}
              className={
                selected === index
                  ? `${styles.tabItemActive} ${styles.tabItem}`
                  : styles.tabItem
              }
              onClick={() => setSelected(index)}
            >
              <div
                className={`${styles['product-icon']} ${
                  currIndex === index ? styles['is-enter'] : styles['is-leave']
                }`}
                style={{
                  backgroundImage: `url(${webPResourceHandleFn(
                    src(`/img/index/product-icon${index + 1}.webp`)
                  )})`,
                }}
                onMouseEnter={() => {
                  setCurrIndex(index);
                }}
                onMouseLeave={() => {
                  setCurrIndex(-1);
                }}
              ></div>
              {item.product}
            </div>
          ))}
        </div>
        <div className={styles.info}>
          <div className={styles.infoLeft}>
            <StreamLakeVideo
              autoPlay
              loop
              muted
              src={src('/img/index/product-background.mp4')}
              webkit-playsinline="true"
              playsInline={true}
              x5-playsinline="true"
            />
            <div className={styles.content}>
              <div className={styles.productTitle}>
                {PRODUCTS[selected].product}
              </div>
              <div className={styles.descText}>
                {PRODUCTS[selected].description}
              </div>
              {PRODUCTS[selected].link && (
                <Button
                  ghost
                  shape="circle"
                  className={styles.btn}
                  icon={<RightOutlined />}
                  onClick={() => handleRoute(PRODUCTS[selected].link)}
                />
              )}
            </div>
          </div>
          <div className={styles.infoRight}>
            {PRODUCTS[selected].features.map((group, index) => (
              <div key={index} className={styles.line}>
                {group.map((item, i) => (
                  <div className={styles.item} key={i}>
                    <div className={styles.background}></div>
                    <div className={styles.text}>
                      <div className={styles.title}>{item.title}</div>
                      <div className={styles.content}>{item.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['collapse-wrapper']}>
        <header className={styles.title}>特色产品</header>
        <FeatureProd prodList={PRODUCTS} />
      </div>
    </FadeIn>
  );
};

export default TopProd;
