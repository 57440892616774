import styles from './style.module.scss';
import { Element } from 'react-scroll';
import FadeIn from '@/components/commonComponents/fadeIn';
import { src } from '@/utils/common';
import classnames from 'classnames';

import { WebPNativeImage as Image } from '../../WebPImage';

const limit = 6;

interface BrandCardProps {
  title: string;
  config: string[];
}

const BrandCard = ({ title, config }: BrandCardProps) => {
  return (
    <Element name={title}>
      <div className={styles['brand']}>
        <FadeIn>
          <div className={styles['brand-title']}>{title}</div>
        </FadeIn>
        <FadeIn>
          <div
            className={classnames({
              [styles['brands']]: true,
              [styles['brands-one-line']]: config.length <= limit,
            })}
          >
            {config.map((item, index) => (
              <div key={index} className={styles['brand-item']}>
                <Image
                  src={src(`/img/${item}.webp`)}
                  alt={item}
                  className={styles['brand-img']}
                />
                {item}
              </div>
            ))}
          </div>
        </FadeIn>
      </div>
    </Element>
  );
};

export default BrandCard;
