import styles from './style.module.scss';
import { useEffect } from 'react';

const FullScreenVideo = ({ src, show, handleShow, clickOverlayClose }) => {
  const handleClickOverlay = () => {
    if (clickOverlayClose) {
      handleShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      const initOverflowConfig = document.body.style.overflow;
      document.body.style.overflow = 'hidden';

      return () => {
        // NOTE: 相比于使用 wheel/touchmove 事件的 preventDefault 方法
        // 使用 document.body.style.overflow的好处是,
        // 移动端还可以支持touchmove 对视频进度条的拖动
        // 而一旦 touchmove 被 preventDefault, 就无法拖动视频的进度条了
        //
        document.body.style.overflow = initOverflowConfig;
      };
    }
  }, [show]);

  if (!show) {
    return null;
  } else {
    return (
      <div className={styles['full-screen-wrapper']}>
        <div
          className={styles['overlay']}
          onClick={() => handleClickOverlay()}
        ></div>
        <video
          src={src}
          controls
          autoPlay={true}
          // eslint-disable-next-line react/no-unknown-property
          webkit-playsinline="true"
          // eslint-disable-next-line react/no-unknown-property
          playsInline={true}
          // eslint-disable-next-line react/no-unknown-property
          x5-playsinline="true"
        />
      </div>
    );
  }
};

export default FullScreenVideo;
