import useUserAgentInfo from './useUserAgentInfo';

const usePolyfillVideo = (): boolean => {
  const { isMobile, browserName } = useUserAgentInfo();
  if (!isMobile) {
    return false;
  }
  return browserName === 'QQBrowser' || browserName === 'UCBrowser';
};

export default usePolyfillVideo;
