// import Collapsible from "react-collapsible";
import { Collapse } from 'antd';

const { Panel } = Collapse;
import { useReducer, useRef, useState } from 'react';
import styles from './style.module.scss';
import FadeIn from '../../commonComponents/fadeIn';
import CollapseContent from '../../commonComponents/collapseContent';
import { flatten } from 'lodash';

const FeatureProd = ({ prodList }) => {
  return (
    <FadeIn>
      <main className={styles.container}>
        <Collapse defaultActiveKey={['0']} expandIconPosition="end">
          {prodList?.map((item, index) => {
            const { product, description, link, features } = item;
            return (
              <Panel header={item.product} key={index}>
                <CollapseContent
                  desc={description}
                  link={link}
                  features={flatten(features)}
                />
              </Panel>
            );
          })}
        </Collapse>
      </main>
    </FadeIn>
  );
};

export default FeatureProd;
