const Host = process.env.NEXT_PUBLIC_CDN_HOST;

const cdnPathMap = {
  xuanchuan: 'kos/nlav11935/streamlake-website/static/home/main.mp4',

  'intel-effect-princess-video':
    'kos/nlav11935/streamlake-website/static/intel-effect/princess.mp4',
  'intel-effect-school-video':
    'kos/nlav11935/streamlake-website/static/intel-effect/school.mp4',
  'intel-effect-mask-video':
    'kos/nlav11935/streamlake-website/static/intel-effect/mask.mp4',
  'intel-effect-ns-shaft-video':
    'kos/nlav11935/streamlake-website/static/intel-effect/ns-shaft.mp4',

  'sdk-demo1': 'kos/nlav11935/streamlake-website/static/sdk/demo1.mp4',
  'sdk-demo2': 'kos/nlav11935/streamlake-website/static/sdk/demo2.mp4',
  'sdk-demo3': 'kos/nlav11935/streamlake-website/static/sdk/demo3.mp4',
  'sdk-demo4': 'kos/nlav11935/streamlake-website/static/sdk/demo4.mp4',

  'beautification-meiyan':
    'kos/nlav11935/streamlake-website/static/beautification/meiyan.mp4',
  'beautification-meiyan-h265':
    'kos/nlav11935/streamlake-website/static/beautification/meiyan-h265.mp4',
  'beautification-meixing':
    'kos/nlav11935/streamlake-website/static/beautification/meixing.mp4',
  'beautification-meizhuang':
    'kos/nlav11935/streamlake-website/static/beautification/meizhuang.mp4',

  'digital-human-assistant':
    'kos/nlav11935/streamlake-website/static/digital-human/assistant.mp4',
  'digital-human-vtuber':
    'kos/nlav11935/streamlake-website/static/digital-human/vtuber.mp4',
  'digital-human-idol':
    'kos/nlav11935/streamlake-website/static/digital-human/idol.mp4',

  'quality-demo1':
    'kos/nlav11935/streamlake-website/static/quality-analysis/demo1.mp4',
  'quality-demo2':
    'kos/nlav11935/streamlake-website/static/quality-analysis/demo2.mp4',
  'quality-demo3':
    'kos/nlav11935/streamlake-website/static/quality-analysis/demo3.mp4',

  'big-screen-demo1':
    'kos/nlav11935/streamlake-website/static/big-screen/demo1.mp4',
  'big-screen-demo2':
    'kos/nlav11935/streamlake-website/static/big-screen/demo2.mp4',
  'big-screen-demo3':
    'kos/nlav11935/streamlake-website/static/big-screen/demo3.mp4',
  '6dof-demo-1': 'kos/nlav11935/streamlake-website/static/6dof/demo-1.mp4',
  '6dof-demo-2': 'kos/nlav11935/streamlake-website/static/6dof/demo-2.mp4',
  '6dof-demo-3': 'kos/nlav11935/streamlake-website/static/6dof/demo-3.mp4',
  'sound-detected-demo-1':
    'kos/nlav11935/streamlake-website/static/sound-detected/demo1.mp4',
  'sound-detected-demo-2':
    'kos/nlav11935/streamlake-website/static/sound-detected/demo2.mp4',
  'sound-detected-demo-4':
    'kos/nlav11935/streamlake-website/static/sound-detected/demo4.mp4',
};

const staticFile = (path) => {
  const Url = new URL('/', Host);
  Url.pathname = path;
  return Url.href;
};

/**
 * cdnMap maker
 * @template T
 * @param {T} sourceMap
 * @returns {T}
 */
const cdnMap = (sourceMap) => {
  return Object.keys(sourceMap).reduce((_map, currentKey) => {
    _map[currentKey] = staticFile(sourceMap[`${currentKey}`]);
    return _map;
  }, {});
};

export const staticFileMap = cdnMap(cdnPathMap);
