const pathLive = '/img/products/live';
const pathEffect = '/img/products/intel-effect';
const pathVOD = '/img/products/VOD';
const pathDH = '/img/products/digital-human';

export const BANNER = {
  title: '视频化升级助推器',
  content:
    '全球领先的音视频及AI服务实践者与提供方，基于前沿的音视频技术和人工智能算法、稳定的云基础设施保障、深度的业务理解与实践，为客户提供全链路智能化音视频解决方案',
  desc: '全球领先的音视频及AI服务实践者与提供方',
  descText:
    '基于前沿的音视频技术和人工智能算法、稳定的云基础设施保障、深度的业务理解与实践，为客户提供全链路智能化音视频解决方案',
};

export const PRODUCTS = [
  {
    product: '点播云',
    link: '/product/vod',
    description:
      '基于数亿级DAU产品实践经验，以点播体验优化为目标，提供集视频上传、存储、分发、播放、数据驱动的体验优化等于一体的精细化点播解决方案型产品，同时，也包含视频分析、画质增强、内容理解等增值能力，为客户提供智能化点播解决方案',
    features: [
      [
        {
          title: '媒资管理',
          content: '提供统一媒体资产管理服务，包含媒资的存储、媒资信息的管理',
          picUrl: `${pathVOD}/icon5.webp`,
        },
        {
          title: '媒体处理',
          content:
            '自研编解码算法、丰富的视频图像基础处理技术、音频处理技术、视频增强与修复技术、视频AI技术等',
          picUrl: `${pathVOD}/icon6.webp`,
        },
      ],
      [
        {
          title: '分发',
          content: '提供融合CDN的智能调度和分发能力，保障音视频分发速度',
          picUrl: `${pathVOD}/icon7.webp`,
        },
        {
          title: '播放',
          content:
            '基于快手网络库、IP优选、预加载策略、高性能解码内核等优势能力，提供播放SDK',
          picUrl: `${pathVOD}/icon8.webp`,
        },
      ],
    ],
  },
  {
    product: '智能特效',
    link: '/product/effect',
    description:
      '基于自研的CV、CG、AR/VR等技术，实现短视频、直播拍摄环节生成2D/3D实时动态贴纸、游戏、自定义触发类特效，真实自然、具有较强趣味性',
    features: [
      [
        {
          title: '渲染引擎SDK',
          content:
            '率先在开放引擎中引入游戏级实时图形渲染能力，对标好莱坞影视级的丰富材质效果库，独有毛绒、清漆等材质效果，全面适配覆盖各类主流、中低端机型及异构硬件设备，性能稳定可靠',
          picUrl: `${pathEffect}/icon5.webp`,
        },
        {
          title: '丰富的魔法表情特效',
          content: '海量线上魔法表情特效素材，每周更新，数十亿作品使用量验证',
          picUrl: `${pathEffect}/icon8.webp`,
        },
      ],
      [
        {
          title: '3D面部特效',
          content:
            '借助媲美硬件的面部动作捕捉能力，实现3D、AR、动态/静态等多种高度贴合面部装扮及特效',
          picUrl: `${pathEffect}/icon9.webp`,
        },
        {
          title: '多种互动玩法',
          content:
            '智能识别手势、表情、动作等触发条件，实时渲染无延迟，支持短视频、拍摄、直播、游戏等多场景互动特效',
          picUrl: `${pathEffect}/icon10.webp`,
        },
      ],
    ],
  },
  {
    product: '直播云',
    link: '/product/live',
    description:
      '基于亿级日活直播平台技术沉淀，提供包含推流、美颜魔表、互动连麦、转码、分发、播放等能力在内的音视频直播产品，同时依托领先的流媒体大数据平台及海内外大规模直播体验优化实践，保障极致直播消费体验',
    features: [
      [
        {
          title: '直播推流',
          content:
            '自研KTP推流协议，保障更流畅、低延时的推流体验；丰富的美颜魔表效果，提供更多趣味功能和玩法',
          picUrl: `${pathLive}/3D-icon1.webp`,
        },
        {
          title: '互动连麦',
          content:
            '凭借自研编码算法、KTP传输协议及业界领先的音频处理算法，保证在丢包、抖动等多种复合网损条件下，依然能够提供清晰流畅的通话体验',
          picUrl: `${pathLive}/3D-icon2.webp`,
        },
      ],
      [
        {
          title: '直播转码',
          content:
            '基于自研K265编码器，结合降噪、锐化、去伪影、ROI等前处理能力和CAPE内容自适应框架，为客户提供更高清、更低码率的流媒体处理服务',
          picUrl: `${pathLive}/3D-icon3.webp`,
        },
        {
          title: '分发及播放',
          content:
            '支持智能调度、动态追帧、IP优选、自适应多码率切换，提供完善的QoS/QoE统计，为客户提供一流的直播播放体验',
          picUrl: `${pathLive}/3D-icon4.webp`,
        },
      ],
    ],
  },
  {
    product: '虚拟人',
    link: '/product/digital-human',
    description:
      '虚拟人服务包含真人驱动模式和智能开播模式，具备面部捕捉、动作捕捉、语音识别等AI能力；支持定制虚拟形象、音色、3D场景及特效，内置丰富的表情库、动作库和素材，为您提供多行业虚拟人解决方案',
    features: [
      [
        {
          title: '虚拟人货场',
          content:
            '基于自研软件全面打造有中之人的品牌人格化、AR场景化营销及3D商品展示的B端电商场景解决方案，通过提升直播间的涨粉效率、互动率、ACU等因素正向影响商家GMV',
          picUrl: `${pathDH}/3D-icon1.webp`,
        },
        {
          title: '智能直播间',
          content:
            '提供一套无中之人的配置系统，让虚拟人在直播间像真人电商主播一样进行商品讲解及售卖，与观众进行互动，增加直播间开播时长，帮助商家争夺无人时段流量提升收入',
          picUrl: `${pathDH}/3D-icon2.webp`,
        },
      ],
      [
        {
          title: '超写实虚拟人',
          content:
            '基于自研引擎及视觉算法的有中之人虚拟开播方案，为个人主播及MCN机构提供低成本开播软件，内置多种素材、特效及玩法，满足用户个性化需求，提升直播效果及商业效率',
          picUrl: `${pathDH}/3D-icon3.webp`,
        },
        {
          title: 'AI偶像',
          content:
            '以歌声合成、多模态合成和对话交互系统为基础，以超写实的视觉及声音效果进行才艺直播及智能创作，具备才艺表演、与观众互动、直播PK等能力，无需真人即可打造偶像IP',
          picUrl: `${pathDH}/3D-icon4.webp`,
        },
      ],
    ],
  },
];

export const BRAND = {
  title: '丰富场景探索与持续技术实践',
  items: [
    '快手',
    'Kwai',
    '一甜',
    '回森',
    '快影',
    '知乎',
    '多多视频',
    '小米',
    '拉拉公园',
    'AcFun',
  ],
};

export const SOLUTIONS = {
  title: '场景化解决方案',
  description: '深耕行业特质、洞察场景化需求，为多行业客户提供一站式解决方案',
  features: [
    {
      title: '广电传媒',
      content:
        '依托丰富且深厚的音视频处理能力，打造匹配该行业迅速转型和升级的全媒体方案',
      icon: '/img/index/solution-icon2.mp4',
      link: '/solutions/radio-tv',
      poster: '/dist/img/index/solution-icon2.png',
      gif: '/img/index/solution-icon2.gif',
    },
    {
      title: '泛娱乐',
      content:
        '提供从内容生产到内容消费的全链路解决方案，帮助客户快速搭建泛娱乐业务',
      icon: '/img/index/solution-icon1.mp4',
      link: '/solutions/entertainment',
      poster: '/dist/img/index/solution-icon1.png',
      gif: '/img/index/solution-icon1.gif',
    },
    {
      title: '电商',
      content:
        '聚焦电商场景，结合人工智能、虚拟现实等技术快速为其注入新的产业创新方向',
      icon: '/img/index/solution-icon4.mp4',
      link: '/solutions/e-commerce',
      poster: '/dist/img/index/solution-icon4.png',
      gif: '/img/index/solution-icon4.gif',
    },
    {
      title: '广电传媒',
      content:
        '依托丰富且深厚的音视频处理能力，打造匹配该行业迅速转型和升级的全媒体方案',
      icon: '/img/index/solution-icon2.mp4',
      link: '/solutions/radio-tv',
      poster: '/dist/img/index/solution-icon2.png',
      gif: '/img/index/solution-icon2.gif',
    },
    {
      title: '泛娱乐',
      content:
        '提供从内容生产到内容消费的全链路解决方案，帮助客户快速搭建泛娱乐业务',
      icon: '/img/index/solution-icon1.mp4',
      link: '/solutions/entertainment',
      poster: '/dist/img/index/solution-icon1.png',
      gif: '/img/index/solution-icon1.gif',
    },
    {
      title: '电商',
      content:
        '聚焦电商场景，结合人工智能、虚拟现实等技术快速为其注入新的产业创新方向',
      icon: '/img/index/solution-icon4.mp4',
      link: '/solutions/e-commerce',
      poster: '/dist/img/index/solution-icon4.png',
      gif: '/img/index/solution-icon4.gif',
    },
  ],
};
