import styles from './style.module.scss';
import { BANNER } from '/utils/const/home';
import { src, handleRoute } from '/utils/common';
import FadeIn from '/components/commonComponents/fadeIn';
import { Button } from 'antd';
import ContactTip from '../contactTip';
import { WebPNextImage as Image } from '../../WebPImage';
import { useWebPResourceCompatibleFn } from '../../../hooks/useWebPResource';
import StreamLakeVideo from '../../StreamLakeVideo';
import usePolyfillVideo from '@/hooks/usePolyfillVideo';
import Link from 'next/link';

const HomeBanner = ({ handleClick }) => {
  const webPResourceHandleFn = useWebPResourceCompatibleFn();
  const shouldVideoPolyfill = usePolyfillVideo();

  // NOTE: 在 UC 和 QQ 浏览器上不要使用 video 来播,否则会虚空悬浮

  const BannerAnimation = shouldVideoPolyfill ? (
    <img
      className={styles.bannerImg}
      src={src('/img/index/home-banner.animate.gif')}
    />
  ) : (
    <StreamLakeVideo
      autoPlay
      loop
      muted
      src={src('/img/index/home-banner.mp4')}
      id="home-banner-video"
      poster={webPResourceHandleFn(src('/img/index/home-banner.webp'))}
      webkit-playsinline="true"
      playsInline={true}
      x5-playsinline="true"
      x5-video-player-type="h5-page"
    />
  );
  return (
    <>
      <div className={styles.banner}>
        <div className={styles.background}></div>
        {BannerAnimation}
        <FadeIn direction={null}>
          <div className={styles.wrapper}>
            <div className={styles.title}>{BANNER.title}</div>
            <div className={styles.content}>{BANNER.content}</div>
            <div className={styles.desc}>{BANNER.desc}</div>
            <div className={styles['desc-text']}>{BANNER.descText}</div>
            <Button className={styles.button}>
              <Link href="/form/consult/experience" passHref>
                免费体验
              </Link>
            </Button>
            <div className={styles['contact-tip']}>
              <ContactTip handleClick={handleClick} />
            </div>
          </div>
        </FadeIn>
      </div>
      {/* 全屏 */}
      <div className={styles['video-wrapper']}>
        <div className={styles.video} onClick={() => handleClick(true)}>
          <Image src={`/img/index/play.webp`} alt="" width={45} height={45} />
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
