import { WebPNativeImage } from '../../WebPImage';
import { useReducer, useRef, useState } from 'react';
import styles from './style.module.scss';
import FadeIn from '../../commonComponents/fadeIn';
import { src } from '../../../utils/common';
import Link from 'next/link';

const CollapseContent = ({ desc, link, features }) => {
  return (
    <div className={styles['content-wrapper']}>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.link}>
        <Link href={link} passHref>
          查看产品详情
        </Link>
      </div>
      <div className={styles.icon}>
        {features.map((item, index) => {
          return (
            <div key={index} className={styles['icon-wrapper']}>
              <WebPNativeImage
                src={src(item.picUrl)}
                className={styles['icon-img']}
              />
              <div className={styles.title}>{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CollapseContent;
