import styles from './style.module.scss';
import { Button } from 'antd';
import FadeIn from '/components/commonComponents/fadeIn';
import router from 'next/router';

const ContactCard = () => {
  return (
    <FadeIn>
      <div className={styles.contact}>
        <div className={styles.title}>StreamLake 合作与生态</div>
        <div className={styles.descText}>
          StreamLake以开放的心态寻求多方共赢，为合作伙伴提供最先进的音视频及AI技术，帮助企业成长、协助伙伴创收
        </div>
        <Button
          className={`${styles.button} ${styles.primaryButton}`}
          onClick={() => router.push('/form/consult/cooperation')}
        >
          成为合作伙伴
        </Button>
        {/* <Button className={styles.button} style={{ width: 204 }}>
              了解更多
            </Button> */}
      </div>
    </FadeIn>
  );
};

export default ContactCard;
