import { Fade, FadeProps } from 'react-awesome-reveal';

const FadeIn = (
  props: FadeProps & {
    key?: React.Key;
    repeat?: boolean;
    children: React.ReactNode | React.ReactNode[];
  }
) => {
  const { repeat, ...others } = props;

  return (
    <Fade
      direction="up"
      cascade
      duration={800}
      triggerOnce={!repeat}
      {...others}
    />
  );
};

export default FadeIn;
